import axios from 'axios'
import local from './local'
import {
	Message
} from 'element-ui'
// import store from '@/store'
// import {getToken} from '@/utils/auth'
const urlArr = [];

// const baseURL = 'https://newapi.aoxiangae.com'; // 测试
// const proName = '测试四方运营';
// const payNameImg = 'default';


const baseURL = 'https://atapi.avpay666.com'; // AVATAR
const proName = 'AVATAR运营';
const payNameImg = 'avatar';

// const baseURL = 'https://api.btjl888.com'; // 币通
// const proName = '财通';
// const payNameImg = 'caitong';

// const baseURL = 'https://api.qxpay999.com'; // 柒星
// const proName = '柒星';
// // const payNameImg = 'qixing';

// const baseURL = 'https://aiapi.aipay666.com'; // 爱付 aiapi.aipay999.com
// const proName = '爱付';
// const payNameImg = 'aipay';


// const baseURL = 'https://cpapi.cpzfpay.com'; // 川普
// const proName = '川普运营';
// const payNameImg = 'cppay';

// const baseURL = 'https://rfapi.rfzfpay.com'; // 川普
// const proName = '瑞丰运营';
// const payNameImg = 'rfpay';

// const baseURL = 'https://leapi.lqmdvks.top'; // 乐E
// const proName = '乐E运营';
// const payNameImg = 'Lee';

// const baseURL = 'https://yfapi.rjixj.com'; // 亿发
// const proName = '亿发运营';
// const payNameImg = 'yifa';




const service = axios.create({
	baseURL: baseURL, // url = base url + request url

	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		config.headers = {
			'Content-Type': 'application/json' //  注意：设置很关键 
		}
		let data = {
			username: local.get('fourusername'),
			tid: local.get('fourtid')
		}
		// if(data.tid && data.username){			
		// 	config.data = Object.assign(config.data, data);
		// }
		// 	return config


		// do something before request is sent
		// console.log('==dd==', config)
		let fullUrl = config.baseURL + config.url
		if (config.allowRepetition) { //允许重复请求数据的接口
			if (config.url != '/manager/login/logout' && config.url != '/manager/login/doLogin' && config.url != '/manager/login/validateGcode' && config.url != '/manager/login/bindGoogle') {

				if (data.tid && data.username) {
					config.data = Object.assign(config.data, data);
				}else{
					window.location.href = '/'
					return Promise.reject({
						message: '账号未登录，请重新登录'
					})
				}
			}
			return config

		} else if (urlArr.indexOf(fullUrl) === -1) { //不允许重复请求数据的接口

			urlArr.push(fullUrl);
			setTimeout(() => {
				urlArr.splice(urlArr.indexOf(fullUrl), 1)
			}, 1000)

			if (config.url != '/manager/login/logout' && config.url != '/manager/login/doLogin'&& config.url != '/manager/login/validateGcode' && config.url != '/manager/login/bindGoogle') {
				if (data.tid && data.username) {
					config.data = Object.assign(config.data, data);
				}else{
					window.location.href = '/'
					return Promise.reject({
						message: '账号未登录，请重新登录'
					})
				}
			}
			return config
		} else {
			return Promise.reject({
				message: '重复请求'
			})
			// return
		}
	},
	error => {
		// do something with request error
		// console.log(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		const res = response.data
		// return res
		// console.log(response)
		// 错误码配置
		
		let fullUrl = response.request.responseURL
		urlArr.splice(urlArr.indexOf(fullUrl), 1)
		if (res.status != 1&&res.status != 3&&res.status != 0) {
			Message({
				message: res.msg || 'Error',
				type: 'error',
				duration: 5 * 1000
			})
			if (res.status === 2) {
				// store.dispatch('user/resetToken')	
				local.remove('fourtid')
				local.remove('fourusername')
				// window.location.href = '/'
				return
			}
			return Promise.reject(new Error(res.msg || 'Error'))
		} else {
			return res
		}
	},
	error => {
		// console.log('err' + error) // for debug
		Message({
			message: error.message,
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.reject(error)
	}
)
export {
	baseURL,
	proName,
	payNameImg
}
export default service
